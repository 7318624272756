<template>
    <div
        class="
            global-page-banner-content
            d-flex
            text-center
            align-center
            justify-center
            ma-auto
            max-height-100
        "
        :style="{ width, height: isMobile ? '42px' : 'auto' }"
    >
        <v-btn
            v-if="!isMobile"
            class="opacity-100"
            size="small"
            color="on-info"
            :icon="mdiChevronLeft"
            variant="plain"
            density="compact"
            @click="go(-1)"
            slim
        ></v-btn>
        <v-carousel
            v-model="model"
            class="mx-3"
            height="100%"
            :interval="interval"
            :show-arrows="false"
            :cycle="!isPaused"
            hide-delimiters
        >
            <v-carousel-item
                v-for="(item, index) in content"
                :key="`global-banner-content-${index}`"
            >
                <div class="d-flex align-center justify-center w-100 h-100">
                    <span v-html="item"></span>
                </div>
            </v-carousel-item>
        </v-carousel>
        <v-btn
            v-if="!isMobile"
            class="opacity-100"
            size="small"
            color="on-info"
            :icon="mdiChevronRight"
            variant="plain"
            density="compact"
            @click="go(1)"
            slim
        ></v-btn>
    </div>
</template>

<script lang="ts" setup>
    import type { GlobalPageBannerType } from '~/types';
    import { useDisplay } from 'vuetify';
    import { mdiChevronLeft, mdiChevronRight } from '@mdi/js';
    import { pageBannerShippingInfo, pageBannerCreditHoldAlert } from '~/data/page-banner-items'

    const props = defineProps<{
        isMobile: boolean;
        type: GlobalPageBannerType;
    }>();

    const useDisplayObj = useDisplay();
    const model = ref<number>(0);
    const interval = ref<number>(0);
    const isPaused = ref<boolean>(true);
    const isAlert = computed<boolean>(() => props.type.endsWith('-alert'));
    const width = computed<string>(() => {
        switch (useDisplayObj.name.value) {
            case 'sm':
            case 'xs':
                return '100%'
            default:
                return isAlert.value ? '100%' : '50%';
        }
    });

    const content = ref<Nullable<string[]>>(null);

    const unWatchType = watch(
        () => props.type,
        (newType) => {
            if (newType) {
                switch (newType) {
                    case 'mbr-credit-hold-alert':
                        content.value = pageBannerCreditHoldAlert;
                        break;
                    case 'shipping-info':
                    default:
                        content.value = pageBannerShippingInfo;
                }

                interval.value = 10000;
            } else {
                interval.value = 0;
            }

            model.value = 0;
            isPaused.value = false;
        },
        {
            immediate: true
        }
    );

    function go(inc: number): void {
        if (content.value === null) {
            return;
        }

        const max = content.value.length - 1;
        let val = model.value + inc;

        if (val > max) {
            val = 0;
        } else if (val < 0) {
            val = max;
        }

        model.value = val;
    }

    onBeforeUnmount(() => {
        unWatchType();
    });
</script>

<style lang="scss"></style>
