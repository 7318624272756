
export const pageBannerShippingInfo: string[] = [
    'Free shipping on domestic orders $99+',
    'Free shipping on international orders $199+',
    'More information on <a class="v-custom-link" href="/company/shipping">shipping policies</a>'
];

export const pageBannerCreditHoldAlert: string[] = [
    'Your account is on credit hold. Please pay <strong>past due <a class="v-custom-link" href="/account/invoices">invoices</a></strong> to place new orders.'
];
